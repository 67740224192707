import React from "react";
import "./_EmployeeDetails.scss";
import Button from "../../Button/index";
import SubTitle from "../../../components/SubTitle/SubTitle";
import { PersonalInfoDetails, ProInfoDetails } from "./EmployeeDetails";
import { openModal, closeModal } from "../../../slices/modals";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../../../components/UploadImg/index";
import getAccess from "../../../utilities/getAccess";
import { deactivationOrDeletionUserAccount } from "../../../slices/user";
import EmployeeItem from "./EmployeeItem";
import { getFormattedDate } from "../../../utilities/getFormattedDate";

const EmployeeDetails = (props) => {
  const { handleClose, id, oneUser, id_user, setNotice } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.fetchMe);
  const handleUserDeactivationOrDeletion = (action) => {
    dispatch(closeModal("confirm-delete-modal"));

    dispatch(deactivationOrDeletionUserAccount({ id:id_user,action })).then((res) => {
      if (res?.error) {
        setNotice({
          text: res?.error?.message,
          open: true,
          state: "error",
        });
      } else {
        if (res?.payload?.message) {
          setNotice({
            text: res?.payload?.message,
            open: true,
            state: "success",
          });

          setTimeout(() => {
            setNotice({
              open: false,
            });
            handleClose(id);
          }, 1500);
        }
      }
    });
  };
  const btnTextSuspendedAccount = oneUser?.isDeactivated ? "activate" : "deactivate"
  return (
    <>
      <div className="EmployeeDetails__header">
        <div className="employeeDetails__identity">
          <Upload
            img={oneUser?.img}
            width={"70px"}
            height={"70px"}
            uploadPhoto={true}
          />
          <div className="employeeDetails__identity_name">
            <span>{oneUser?.fullName}</span>
            <p>{oneUser?.jobTitle}</p>
          </div>
        </div>
        <div className="button_wraper">
          {
           getAccess(
            user?.permissions,
            user?.permissionGroups?.[0]?.permissions,
            "USER",
            "EDIT"
          ) && (
            <>
              <Button
                text={btnTextSuspendedAccount.charAt(0).toUpperCase() + btnTextSuspendedAccount.slice(1)}
                className={"toggle-status-button"}
                type="button"
                onClick={() =>
                  dispatch(
                    openModal("confirm-delete-modal", {
                      id: id_user,
                      action: btnTextSuspendedAccount,
                      Item: "employee",
                      deleteHandler: () => handleUserDeactivationOrDeletion(btnTextSuspendedAccount),
                    })
                  )
                }
            /> 
             
              <Button
              text={"Delete"}
              className={"delete_button"}
              type="button"
              onClick={() =>
                dispatch(
                  openModal("confirm-delete-modal", {
                    id: id_user,
                    action:"delete",
                    Item: "employee",
                    deleteHandler: () => handleUserDeactivationOrDeletion('delete'),
                  })
                )
              }
            />
            </>
          )}
          {getAccess(
            user?.permissions,
            user?.permissionGroups?.[0]?.permissions,
            "USER",
            "EDIT"
          ) && (
            <Button
              text={"Edit Employee"}
              className={"edit-button"}
              onClick={() =>
                dispatch(openModal("UpdateUserModal", oneUser)) ||
                handleClose(id)
              }
            />
          )}
        </div>
      </div>
      
      {oneUser?.statusUpdatedAt && 
        <EmployeeItem
        label={`Last Date Account ${Boolean(oneUser?.isDeactivated) ? `deactivation` : `activation`}`}
        info={getFormattedDate(new Date(oneUser?.statusUpdatedAt), "-",true)}
        color="red"
        />
      }
      
      <SubTitle
        subTitle={"Personal Info"}
        className="subtitle__employee_info"
      />
      <PersonalInfoDetails
        fullName={oneUser?.fullName}
        email={oneUser?.email}
        phone={oneUser?.phone}
        birthday={oneUser?.birthday}
        gender={oneUser?.gender}
        address={oneUser?.address}
      />
      <div style={{ marginTop: "34px" }}>
        <SubTitle
          subTitle={"Professional Info"}
          className="subtitle__employee_info"
        />
      </div>
      <ProInfoDetails
        hiringDate={oneUser?.hiringDate}
        jobTitle={oneUser?.jobTitle}
        department={oneUser?.department?.name}
        status={oneUser?.status}
        role={oneUser?.role?.code}
        balance={oneUser?.balance.toFixed(2)}
        authorisationBalance={oneUser?.authorisationBalance.toFixed(2)}
        balanceLeft={oneUser?.balanceLeft?.toFixed(2)}
        authorisationBalanceLeft={oneUser?.authorisationBalanceLeft?.toFixed(2)}
      />
    </>
  );
};

export default EmployeeDetails;
