import React from "react";

const EmployeeItem = (props) => {
  const { label, info ,className , color="#9B9EAD"} = props;

  return (
    <div className={className ? className :`info_wrapper`}>
      <span style={{ color ,marginRight:"8px" }}>{label}</span>
      <span className={className} style={{color}} >{info}</span>
    </div>
  );
};

export default EmployeeItem;
